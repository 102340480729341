import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { assert } from '@chainflip/utils/assertion';
import { CHAINFLIP_SS58_PREFIX } from '@chainflip/utils/consts';
import * as ss58 from '@chainflip/utils/ss58';
import { initPolkadotSnap, isPolkadotSnapInstalled } from '@chainsafe/metamask-polkadot-adapter';
import type { Signer as InjectedSigner } from '@polkadot/api/types';
import { useQuery } from '@tanstack/react-query';

export type ChainflipAccountId = `cF${string}`;

const reencodeSS58 = (address: string): ChainflipAccountId => {
  const decoded = ss58.decode(address);
  return ss58.encode({ ...decoded, ss58Format: CHAINFLIP_SS58_PREFIX }) as ChainflipAccountId;
};

export interface InjectedExtension {
  name: string;
  version: string;
}

export interface InjectedAccountWithMeta {
  idHex: `0x${string}`;
  idSs58: ChainflipAccountId;
  readonly?: false;
  meta: {
    name?: string;
    source: string;
  };
}

type ReadonlyAccount = Omit<InjectedAccountWithMeta, 'meta' | 'readonly'> & {
  readonly: true;
};

type PolkadotContext = {
  injectedExtensions: InjectedExtension[];
  injectedAccounts: InjectedAccountWithMeta[];
  isInitializingExtensions: boolean;
  connectSnap: () => Promise<InjectedAccountWithMeta>;
  selectedAccount: InjectedAccountWithMeta | ReadonlyAccount | null;
  selectedSigner: InjectedSigner | null;
  selectAccount: (account: InjectedAccountWithMeta | null) => void;
};

type RequireKeys<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};

type AssertedPolkadotContext = RequireKeys<PolkadotContext, 'selectedAccount'>;

export const MM_SNAP_SOURCE = 'metamask-polkadot-snap';
export const POLKADOT_JS_SOURCE = 'polkadot-js';
export const SUBWALLET_JS_SOURCE = 'subwallet-js';

const initMetamaskSnap = async () => {
  await initPolkadotSnap({
    config: {
      networkName: 'chainflip',
      addressPrefix: 2112,
      unit: {
        symbol: 'FLIP',
        decimals: 18,
      },
      // despite the ws prefix the package expects a http url: https://github.com/ChainSafe/metamask-snap-polkadot/blob/c569a7edcef618ded74e72b729ac76229fe06234/packages/snap/src/polkadot/api.ts#L13
      wsRpcUrl: process.env.NEXT_PUBLIC_STATECHAIN_RPC_HTTP_URL,
    },
  });
};

const getExtensionData = async (appName: string) => {
  // if the snap is connected we need to initialize it before we call web3Enable to access its accounts
  const isSnapConnectedToPage = await isPolkadotSnapInstalled('npm:@chainsafe/polkadot-snap');
  if (isSnapConnectedToPage) {
    await initMetamaskSnap();
  }

  const { web3Accounts, web3Enable } = await import('@polkadot/extension-dapp');
  const injectedExtensions = await web3Enable(appName);

  let accounts: InjectedAccountWithMeta[] = [];
  if (injectedExtensions.length > 0) {
    const polkadotAccounts = await web3Accounts();
    accounts = polkadotAccounts
      // subwallet includes ethereum accounts if the evm wallet is connected to the page
      // ethereum accounts are only compatible with moonbeam: https://polkadot.js.org/docs/keyring/start/basics/#keypair-types
      .filter((account) => account.type !== 'ethereum')
      .map((account) => ({
        ...account,
        idHex: ss58.toPublicKey(account.address),
        idSs58: reencodeSS58(account.address),
        readonly: false,
      }));
  }

  return { injectedExtensions, injectedAccounts: accounts };
};

const tryParseAccount = (): ReadonlyAccount | undefined => {
  const accountId = new URLSearchParams(window.location.search).get('accountId');
  if (typeof accountId !== 'string') return undefined;

  try {
    return {
      idHex: ss58.toPublicKey(accountId),
      idSs58: reencodeSS58(accountId),
      readonly: true,
    };
  } catch {
    return undefined;
  }
};

export const pathsWithoutAccount = ['/onboard', '/disconnect', '/'];

const Context = createContext<PolkadotContext | null>(null);

type ProviderProps = {
  children: React.ReactNode;
  polkadotAppName: string;
  storeAccount: InjectedAccountWithMeta | null;
  savePolkadotAccount: PolkadotContext['selectAccount'];
};

export const PolkadotProvider = ({
  children,
  polkadotAppName,
  storeAccount,
  savePolkadotAccount,
}: ProviderProps): JSX.Element => {
  const [queryEnabled, setQueryEnabled] = useState(true);
  const {
    data: extensionData = { injectedExtensions: [], injectedAccounts: [], isSnapConnected: false },
    isPending: isInitializingExtensions,
    refetch: refetchExtensionData,
  } = useQuery({
    queryKey: ['polkadotContext'],
    queryFn: () => getExtensionData(polkadotAppName),
    refetchInterval: 1000,
    enabled: queryEnabled,
  });
  const router = useRouter();
  const [readonlyAccount, setReadonlyAccount] = useState<ReadonlyAccount | undefined>(
    tryParseAccount,
  );

  useEffect(() => {
    const acct = tryParseAccount();
    if (acct) setReadonlyAccount(acct);
  }, [window.location.search]);

  // keep readonly account id in query to allow for refreshing page
  useEffect(() => {
    if (router.isReady && !router.query.accountId && readonlyAccount) {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, accountId: readonlyAccount.idSs58 },
      });
    }
  }, [router.isReady, router.query.accountId, readonlyAccount]);

  const selectedAccount = readonlyAccount ?? storeAccount;
  const selectedSigner = useMemo(() => {
    const extension = !selectedAccount?.readonly
      ? extensionData.injectedExtensions.find((e) => e.name === selectedAccount?.meta.source)
      : undefined;

    return extension?.signer ?? null;
  }, [selectedAccount, extensionData]);

  useEffect(() => {
    if (extensionData.injectedExtensions.length > 0) {
      setQueryEnabled(pathsWithoutAccount.includes(router.pathname));
    }
  }, [extensionData, router.pathname]);

  const selectAccount = useCallback(
    (account: InjectedAccountWithMeta | null) => {
      if (readonlyAccount) setReadonlyAccount(undefined);
      savePolkadotAccount(account);
    },
    [readonlyAccount, savePolkadotAccount],
  );

  const connectSnap = async () => {
    // fetching extensions after snap initialization triggers the metamask connect popup if the user did not connect yet
    await initMetamaskSnap();
    const updatedExtensions = await refetchExtensionData();
    const snapAccount = updatedExtensions.data?.injectedAccounts.find(
      (a) => a.meta.source === MM_SNAP_SOURCE,
    );
    assert(snapAccount, 'no metamask snap account injected');

    return snapAccount;
  };

  const value = useMemo(
    () => ({
      ...extensionData,
      isInitializingExtensions,
      connectSnap,
      selectedAccount,
      selectedSigner,
      selectAccount,
    }),
    [extensionData, selectedAccount],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function usePolkadot(): PolkadotContext;
export function usePolkadot(assertAccountSelected: true): AssertedPolkadotContext;
export function usePolkadot(
  assertAccountSelected?: true,
): PolkadotContext | AssertedPolkadotContext {
  const context = useContext(Context);
  if (context === null) {
    throw new Error('usePolkadot must be used within a PolkadotProvider');
  }

  if (assertAccountSelected) {
    if (context.selectedAccount === null) {
      throw new Error('No Polkadot account selected');
    }
  }

  return context;
}
