import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { usePolkadot, pathsWithoutAccount } from '@/shared/hooks/usePolkadot';

export default function EnsureAccount({ children }: { children: React.ReactNode }) {
  const { selectedAccount } = usePolkadot();
  const router = useRouter();

  // redirect user to requested page after account connection if this is the first page they load
  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => setIsInitialRender(false), []);

  if (!selectedAccount && !pathsWithoutAccount.includes(window.location.pathname)) {
    router.push({
      pathname: '/',
      query: isInitialRender
        ? { redirectUrl: window.location.pathname + window.location.search }
        : {},
    });
    return null;
  }

  return children;
}
